<template>
  <div class="content">
    <div>
      <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1342 34.3295L32.17 33.3653C26.9235 28.1188 26.1329 20.1038 29.7981 14.0229L52.4766 36.7014C46.3957 40.3666 38.3807 39.576 33.1342 34.3295ZM59.7471 38.5722C55.0246 43.2947 48.6758 45.3998 42.5035 44.8874L44.6624 60H48.0606C49.7174 60 51.0606 61.3431 51.0606 63C51.0606 64.6568 49.7174 66 48.0606 66H42.1047C42.0727 66.0005 42.0407 66.0005 42.0089 66H24.1266C24.0844 66.0009 24.0421 66.0009 23.9996 66H18.0605C16.4037 66 15.0605 64.6568 15.0605 63C15.0605 61.3431 16.4037 60 18.0605 60H21.6845L27.1403 36.8129L27.1466 36.7864C19.4153 28.2214 19.6755 15.0042 27.9273 6.75238C28.7794 5.90033 30.1608 5.90033 31.0129 6.75238L43.0392 18.7787L44.4392 17.3786C45.6108 16.2071 47.5103 16.2071 48.6819 17.3786C49.8534 18.5502 49.8534 20.4497 48.6819 21.6213L47.2818 23.0213L59.7471 35.4866C60.5992 36.3387 60.5992 37.7201 59.7471 38.5722ZM27.8484 60H38.6015L36.313 43.9803L32.1119 41.8798L27.8484 60Z" fill="#57BA5E"/>
      </svg>
      <span>Кажется вы потерялись</span>
      <p>Страницы, которую вы ищите, не существует</p>
      <div class="button">
        <ECButton
          label="На главную"
          view="white"
          @click="$router.push('/')"
        />
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
  .content{
    background: rgba(0,0,0, .5);
    margin: 0;
    max-width: 100vw;
    height: calc(100vh - 74px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }
  div{
    max-width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span{
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      letter-spacing: -0.02em;
      color: white;
      margin-top: 16px;
      @media (max-width: 500px){
        font-size: 28px;
      }
    }
    p{
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 16px;
      @media (max-width: 500px){
        font-size: 15px;
      }
    }
    .button{
      width: 310px;
      margin-top: 25px;
    }

  }
</style>
